import React, { useState } from "react";
import {
  Typography,
  Divider,
  ListItem,
  IconButton,
  Box,
  Button,
  Link,
  Grid,
  Chip
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/StopCircleOutlined";
import { Message, Resource } from "./Message";
import { api_url } from "../../../config/api";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './MessageBubble.css';
import { getAccessToken } from "../../../utils/auth";
import MessageActions from "./MessageActions";
import ResourceCarousel from "./ResourceCarousel";

// A single audio element reference
let audio: HTMLAudioElement | null = null;

const playMessage = async (
  messageId: string,
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>
) => {
  // Indicate that playback is starting
  setIsPlaying(true);

  try {
    // Retrieve token if needed
    const accessToken = await getAccessToken();

    // Generate a signed URL for the audio file
    // because the HTML Audio element does not support passing auth headers
    const response = await fetch(`${api_url}/create_signed_audio_url`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ message_id: messageId }),
    });
  
    if (!response.ok) {
      console.error("Failed to get signed URL");
      return;
    }
  
    const data = await response.json();
    const signedUrl = data.signed_url;

    // Construct a direct streaming URL with query params
    // Note: Must be supported by your backend
    const audioUrl = signedUrl;

    // Create a new HTMLAudioElement pointing to your endpoint
    audio = new Audio(audioUrl);

    // When playback finishes
    audio.onended = () => {
      setIsPlaying(false);
      audio = null;
    };

    // If an error occurs
    audio.onerror = (err) => {
      console.error("Error playing message:", err);
      setIsPlaying(false);
      audio = null;
    };

    // Start playback
    await audio.play();
  } catch (error: any) {
    console.error("Error playing message:", error);
    setIsPlaying(false);
  }
};

const stopMessage = (
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>
) => {
  // Pause and reset
  if (audio) {
    audio.pause();
    audio = null;
  }

  setIsPlaying(false);
};

interface MessageBubbleProps {
  msg: Message;
  messageRetrievalHandler: (messageId: string) => void;
}

// Modified ListItemText to include conditional styling and play button
const MessageBubble = ({ msg, messageRetrievalHandler }: MessageBubbleProps) => {

  // const mockResources = [
  //   {
  //     'title': 'Clinical Dataset #1',
  //     'type': 'clinical',
  //     'uri': '',
  //     'source': 'referenced'
  //   },
  //   {
  //     'title': 'Clinical Dataset #2',
  //     'type': 'clinical',
  //     'uri': '',
  //     'source': 'referenced'
  //   },
  //   {
  //     'title': 'Sound Relationship House',
  //     'type': 'module',
  //     'uri': 'https://beta.gottmanconnect.com/user/workshop/home/23?id_module=55',
  //     'source': 'referenced'
  //   },
  //   {
  //     'title': 'Friendship and Intimacy',
  //     'type': 'module',
  //     'uri': 'https://beta.gottmanconnect.com/user/workshop/home/23?id_module=56',
  //     'source': 'supplemental'
  //   },
  //   {
  //     'title': 'Manage Conflict',
  //     'type': 'module',
  //     'uri': 'https://beta.gottmanconnect.com/user/workshop/home/23?id_module=57',
  //     'source': 'supplemental'
  //   },
  //   {
  //     'title': 'Stonewalling vs The Silent Treatment: Are They The Same?',
  //     'type': 'blog',
  //     'uri': 'https://www.gottman.com/blog/stonewalling-vs-the-silent-treatment-are-they-the-same/',
  //     'source': 'supplemental'
  //   }
  // ]
  const [isPlaying, setIsPlaying] = useState(false);
  const isUserMessage = msg.sender === "User"; // Adjust condition based on your data structure
  const bubbleClass = isUserMessage ? 'bubble user' : 'bubble ai';

  const togglePlayMessage = (message: Message) => {
    if (isPlaying) {
      stopMessage(setIsPlaying);
    } else {
      playMessage(message.id!, setIsPlaying);
    }
  };

  const formatText = (text: string) => {
    return text.replace(/(?:\r\n|\r|\n)/g, "<br/>");
  }

  return (
    <ListItem className="bubble-list-item">
      <div className={bubbleClass}>
        {msg.sender === "User" && (
          <Typography
            component="span"
            variant="body2"
            dangerouslySetInnerHTML={{ __html: formatText(msg.text) }}
          >
          </Typography>
        )}
        {msg.sender === "AI" && (
          <>
            <img
              src="/EmiLogoNotTransparent.svg"
              alt="Emi Icon"
              className="emi-icon"
            />
            <div style={{ display: 'grid', gap: '16px' }}>
              <Typography
                component="span"
                variant="body2"
                dangerouslySetInnerHTML={{ __html: formatText(msg.text) }}
              >
              </Typography>
            </div>
            {/* {msg.tags && <div className='ai-tags'>
              {msg.tags.map((tag) => (
                <Chip key={tag} label={tag} />
              ))}
            </div>} */}
            <MessageActions
              msg={msg}
              isPlaying={isPlaying}
              togglePlayMessage={togglePlayMessage}
            />
            {msg.resources && (
              <ResourceCarousel resources={msg.resources as Resource[]} />
            )}
          </>
        )}
      </div>
      <Divider />
    </ListItem>
  );
};

export default MessageBubble;
