import React, { useRef } from 'react';
import { Box, Typography, Link, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ClinicalIcon from '@mui/icons-material/LocalHospital';
import BlogIcon from '@mui/icons-material/Subject';
import ModuleIcon from '@mui/icons-material/MenuBook';
import VideoIcon from '@mui/icons-material/OndemandVideo';
import { Resource } from './Message';

/* -------------------
   Types
-------------------- */

interface ResourceFlexScrollProps {
  resources: Resource[];
}

/* -------------------
   Helper
-------------------- */
const truncateTitle = (title: string, maxLength = 30): string =>
  title.length > maxLength ? title.substring(0, maxLength - 3) + '...' : title;

/* -------------------
   Main Component
-------------------- */
const ResourceFlexScroll: React.FC<ResourceFlexScrollProps> = ({ resources }) => {
  // Create a ref for the scrollable container
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  // Scroll the container to the left by a fixed offset
  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  // Scroll the container to the right by a fixed offset
  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  return (
    <Box position="relative" mt={2}>
      {/* Left Arrow */}
      <IconButton
        onClick={scrollLeft}
        sx={{
          position: 'absolute',
          left: 0,
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 1,
          backgroundColor: 'rgba(0,0,0,0.5)',
          '&:hover': { backgroundColor: 'rgba(0,0,0,0.7)' },
        }}
      >
        <ChevronLeftIcon sx={{ color: '#fff' }} />
      </IconButton>

      {/* Scrollable Flex Container */}
      <Box
        ref={scrollContainerRef}
        sx={{
          overflowX: 'auto',
          display: 'flex',
          scrollBehavior: 'smooth',
          padding: '16px 48px', // Padding keeps cards clear of the arrow areas
          scrollbarWidth: 'none', // Hide scrollbar for Firefox
          '&::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar for Chrome, Safari, Opera
        }}
      >
        {resources.map((resource, index) => {
          const { title, uri, type, source } = resource;
          const displayTitle = truncateTitle(title);
          const isReferenced = source === 'referenced';
          const isClickable = uri.trim() !== '';

          // Define colors
          const baseTextColor = isReferenced ? '#1976d2' : '#333';
          const textColor = isClickable ? baseTextColor : '#757575';
          const backgroundColor = isReferenced ? '#e3f2fd' : '#f5f5f5';

          // Card styling: fixed height and minWidth ensure consistent sizing
          const cardStyle: React.CSSProperties = {
            backgroundColor,
            borderRadius: '8px',
            minWidth: '200px',
            height: '200px',
            padding: '16px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            marginRight: '16px',
            border: '1px solid #ccc',
          };

          // Icon selection based on resource type
          let typeIcon: JSX.Element | null = null;
          switch (type.toLowerCase()) {
            case 'clinical':
              typeIcon = <ClinicalIcon sx={{ fontSize: '1.5rem', mb: 0.5, color: textColor }} />;
              break;
            case 'blog':
              typeIcon = <BlogIcon sx={{ fontSize: '1.5rem', mb: 0.5, color: textColor }} />;
              break;
            case 'module':
            case 'lesson':
              typeIcon = <ModuleIcon sx={{ fontSize: '1.5rem', mb: 0.5, color: textColor }} />;
              break;
            case 'video':
              typeIcon = <VideoIcon sx={{ fontSize: '1.5rem', mb: 0.5, color: textColor }} />;
              break;
            default:
              break;
          }

          // Optional label styling
          const labelStyle: React.CSSProperties = {
            position: 'absolute',
            top: 8,
            right: 8,
            backgroundColor: isReferenced ? '#1976d2' : '#999',
            color: '#fff',
            fontSize: '0.7rem',
            padding: '2px 6px',
            borderRadius: '4px',
            textTransform: 'lowercase',
          };

          // Build the card content
          const cardContent = (
            <Box style={cardStyle}>
              <Box style={labelStyle}>{source}</Box>
              {typeIcon}
              <Typography variant="body2" sx={{ color: textColor, textAlign: 'center' }}>
                {displayTitle}
              </Typography>
            </Box>
          );

          // For clickable resources, wrap the card in a Link that exactly matches the card's dimensions.
          return isClickable ? (
            <Link
              key={index}
              href={uri}
              target="_blank"
              underline="none"
              sx={{ display: 'inline-block' }}
            >
              {cardContent}
            </Link>
          ) : (
            <div key={index}>{cardContent}</div>
          );
        })}
      </Box>

      {/* Right Arrow */}
      <IconButton
        onClick={scrollRight}
        sx={{
          position: 'absolute',
          right: 0,
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 1,
          backgroundColor: 'rgba(0,0,0,0.5)',
          '&:hover': { backgroundColor: 'rgba(0,0,0,0.7)' },
        }}
      >
        <ChevronRightIcon sx={{ color: '#fff' }} />
      </IconButton>
    </Box>
  );
};

export default ResourceFlexScroll;
