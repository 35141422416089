import React, { useState, useEffect } from "react";
import {
  Box,
  CssBaseline,
  Drawer,
  Toolbar,
  AppBar,
  Typography,
  Divider,
  IconButton,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Chat from "./Chat";
import axios from "axios";
import "@aws-amplify/ui-react/styles.css";
import Sidebar from "./Sidebar";
import { api_url } from "../../../config/api";
import { Message } from "./Message";
import './ChatPage.css'; // Import the CSS file
import { useNavigate } from "react-router-dom";
import { getAccessToken } from "../../../utils/auth";

const drawerWidth = 360;

const ChatPage: React.FC = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const [open, setOpen] = useState<boolean>(false);
  const [startingMessage, setStartingMessage] = useState<Message | null>(null);
  const [version, setVersion] = useState<string>("");
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [selectedModel, setSelectedModel] = useState('emi_v1');

  const handleSetSelectedModel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedModel(event.target.value);
  }

  const startNewSession = async () => {
    const accessToken = await getAccessToken();
    // console.log(accessToken);
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    }
    try {
      const response = await axios.post(`${api_url}/start_session`, {}, { headers });
      const sessionId = response.data.session_id;
      const startingMessage = response.data.message;
      const startingMessageId = response.data.message_id;
      setSessionId(sessionId);
      setStartingMessage({ 'sender': "AI", 'text': startingMessage, 'id': startingMessageId });
      console.log(`Session ID ${sessionId}`);
    } catch (error) {
      console.error("Error starting new session", error);
    }
  };

  useEffect(() => {
    async function fetchModelMetadata() {
      const accessToken = await getAccessToken();
      // console.log(accessToken);
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      }
      try {
        const response = await axios.get(`${api_url}/model_metadata`, { headers });
        setVersion(response.data.version);
      } catch (error) {
        console.error("Error fetching model metadata", error);
      }
    }
    fetchModelMetadata();
  }, []);

  useEffect(() => {
    if (user && !sessionId) {
      startNewSession();
    }
  }, [user, sessionId]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <Box className="chat-page">
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: open ? `calc(100% - ${drawerWidth}px)` : "100%",
          ml: open ? `${drawerWidth}px` : 0,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Box display="flex" justifyContent="center" alignItems="center" width="100%">
            <img src="/GottmanLogo.svg" alt="Gottman Logo" className="navbar-logo" />
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Button
            className="sign-out-button"
            color="inherit"
            onClick={handleSignOut}
            sx={{
              "&:hover": {
                backgroundColor: "red",
              },
            }}
          >
            Sign Out
          </Button>
        </Toolbar>
      </AppBar>
      <>
        <Drawer
          sx={{
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: { xs: '100vw', sm: `${drawerWidth}px` }, // Full width on mobile, fixed width on desktop
              height: '100vh', // Full height
              margin: 0, // Remove any margin
            },

            ...(open ? {} : { display: "none" }),
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <Sidebar
            sessionId={sessionId}
            version={version}
            handleDrawerToggle={handleDrawerToggle}
            startNewSession={startNewSession}
            handleSignOut={handleSignOut}
            selectedModel={selectedModel}
            handleSetSelectedModel={handleSetSelectedModel}
          />
        </Drawer>
        {sessionId && (
          <Box
            sx={{
              width: open ? `calc(100vw - ${drawerWidth}px)` : '100vw', // Adjust width dynamically
              marginLeft: open ? `${drawerWidth}px` : 0, // Offset content when drawer is open
              transition: 'margin-left 0.3s ease, width 0.3s ease', // Smooth transition,
              '@media (max-width: 600px)': {
                display: open ? 'none' : 'block', // Hide on mobile when drawer is open
              },
            }}
          >
            {startingMessage && (
              <Chat 
                    sessionId={sessionId} 
                    startingMessage={startingMessage}
                    modelName={selectedModel}
              />
            )}
          </Box>)}
      </>
    </Box>
  );
};

export default ChatPage;
